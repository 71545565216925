import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
// import Banner from './Banner'
import Logo from './Logo'

import Facebook from '../Icons/Facebook'
import Instagram from '../Icons/Instagram'
import Twitter from '../Icons/Twitter'
import LinkedIn from '../Icons/LinkedIn'
import Weibo from '../Icons/Weibo'
import Loading from './Loading'

const Banner = lazy(() => import("./Banner"));


const Section = styled.section`
min-height: 100vh;
width: 100vw;
background-color: ${props => props.theme.body};
position: relative;
color: ${(props) => props.theme.text};

display: flex;
/* justify-content: center; */
/* align-items: center; */
flex-direction: column;
`

const Container = styled.div`
width: 75%;
margin: 2rem auto;
display: flex;
justify-content: space-between;
align-items: center;

border-bottom: 1px solid ${(props) => props.theme.text};

@media (max-width: 48em) {
width: 90%;

h1{
font-size: ${props => props.theme.fontxxxl};

}
}
`
const Left = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 48em) {
width: 100%;
}
`

const IconList = styled.div`
display: flex;
align-items: center;
margin: 1rem auto;

&>*{
  padding-right: 0.5rem;
  transition: all 0.2s ease;

  &:hover{
    transform: scale(1.2);
  }
}
`
const MenuItems = styled.ul`
list-style:none;
width: 50%;
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(3, 1fr);
grid-gap: 1rem;

@media (max-width: 48em) {
display: none;
}
`
const Item = styled.li`
width: fit-content;
cursor: pointer;

&::after{
  content: ' ';
  display: block;
  width: 0%;
  height: 2px;
  background: ${props => props.theme.text};
  transition: width 0.3s ease;
}
&:hover::after{
  width: 100%;
}
`

const Bottom = styled.div`
width: 75%;
margin: 0 auto;
display: flex;
justify-content: space-between;
align-items: center;

a{
  text-align: right;
}
@media (max-width: 48em) {
flex-direction: column;
width: 100%;

span{
  margin-bottom: 1rem;
}
}
`

const Footer = () => {

  const scrollTo = (id) => {

    let element = document.getElementById(id);
  
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    })
  }
  
  return (
<Section>
      
  <Suspense fallback={<Loading />}>
  <Banner /> </Suspense>

      <Container>
    <Left> 
    <Logo />
    <IconList>
      <a href="https://weibo.com/u/5772949080" target='_blank' 
      rel="noopener noreferrer"
      aria-label='weibo'
      >
         <Weibo />
      </a>
      <a href="#" target='_blank' 
      rel="noopener noreferrer"
      aria-label='facebook'
      >
        <Facebook />
      </a>
      <a href="#" target='_blank' 
      rel="noopener noreferrer"
      aria-label='instagram'
      >
        <Instagram />
      </a>
      <a href="#" target='_blank' 
      rel="noopener noreferrer"
      aria-label='twitter'
      >
        <Twitter />
      </a>
      <a href="#" target='_blank' 
      rel="noopener noreferrer"
      aria-label='linkedin'
      >
        <LinkedIn />
      </a>
    </IconList>
    </Left>
    <MenuItems>
      <Item onClick={() => scrollTo('home')}  >主页</Item>
      <Item onClick={() => scrollTo('showcase')}  >新品</Item>
      <Item>
        <a href="#" target='_blank' rel="noopener noreferrer">知识产权保护</a>
      </Item>
      <Item>
        <a href="https://file.moqic.com/permission/PrivacyStatement/PrivacyStatement240220.pdf" target='_blank' rel="noopener noreferrer">隐私声明</a>
      </Item>
      <Item onClick={() => scrollTo('roadmap')}  >联系我们</Item>
      <Item onClick={() => scrollTo('faq')}  >媒体资料库</Item>
      <br/>
    </MenuItems>
      </Container>
      <Bottom>
        <span>
          2024 - {new Date().getFullYear()} COPYRIGHT © 末祁潮聚 MOQIC.COM ALL RIGHTS RESERVED
          <br/>
          上海市黄浦区南京东路300号恒基名人L12
        </span>
        <span>
          <a href="#" target="_blank" rel="dofollow noreferrer">
          沪公网安备31010102007706号
          </a>
          <br/>
          <span> </span>
          <a href="http://beian.miit.gov.cn/" target="_blank" rel="dofollow noreferrer">
          沪ICP备2024050287号-2
          </a>
        </span>
      </Bottom>
    </Section>
  )
}

export default Footer
