import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components';
import Accordion from '../Accordion';


const Section = styled.section`
min-height: 100vh;
height: auto;
width: 100vw;
background-color: ${props => props.theme.text};
position: relative;
color: ${(props) => props.theme.body};
overflow: hidden;


display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};
  
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em){
  font-size: ${(props) => props.theme.fontxl};

  }
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em){
  width: 80%;
  }
  @media (max-width: 48em){
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{

    margin-top: 0;
}

  }
  }
`
const Box = styled.div`
width: 45%;
@media (max-width: 64em){
  width: 90%;
  align-self: center;
  }

`

const Faq = () => {

const ref = useRef(null);
gsap.registerPlugin(ScrollTrigger);
useLayoutEffect(() => {
  
  let element = ref.current;

  ScrollTrigger.create({
    trigger: element,
    start:'bottom bottom',
    end:'bottom top',
    pin:true,   
    pinSpacing:false, 
    scrub:1,
    // markers:true,
  })

  return () => {
    ScrollTrigger.kill();
  };
}, [])

  return (
    <Section ref={ref} id="faq">
    <Title>Faq</Title>

    <Container>

<Box>
  <Accordion ScrollTrigger={ScrollTrigger} title='我们即将发行哪些重磅IP的潮玩周边?' >
  目前，包含在全国范围以内的所有知名IP都是我们的目标,我们正在努力洽谈和商议中,我们会尽快以最完美的呈现带给末祁潮聚的用户。
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title='我喜欢的IP什么时候可以来到末祁潮聚?' >
  如果您有喜欢的IP且十分愿意与我们分享，请告诉我们，请投递邮件至：userip@moqic.com，标题以“我喜欢的IP”，我们看到后会根据实时热度去推动你想要的IP，注意这份邮件多次发送将视作无效，请勿反复刷邮件，谢谢！

  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title='我所在的城市什么时候会有末祁潮聚的实体店?' >
  我们会按照上海→成都→深圳的顺序去开店，若您所在的城市不在以上三个地区，请来我们的VR体验店，云逛体验吧！
  </Accordion>
</Box>
<Box>
  <Accordion ScrollTrigger={ScrollTrigger} title='末祁潮聚接受媒体的采访吗? ' >
  非常抱歉，我们目前没有接受媒体采访的能力和空间，如果您想获得我们的回答，请投递邮件至：userqa@moqic.com，我们会很乐意回答您的问题。我们承诺每一份邮件都会认真回复，但是这不包括恶意投递。
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title='我想给末祁潮聚提一些建议，我想知道怎么提问，同时我的问题会受到末祁潮聚的重视吗?' >
  我们会很乐意回答您的问题，我们也很喜欢收获一些能令我们用户体验更好的建议，如果您想获得我们的回答，请投递邮件至：userqa@moqic.com，我们承诺每一份邮件都会认真回复，但是这不包括恶意投递。
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title='如何在社招领域加入末祁潮聚?' >
  我们会偶尔在部分平台上发布我们的招聘信息，包括但不限于各大热门招聘APP，也欢迎您对您的专长投递邮件来介绍一下您自己，欢迎投递：hr@moqic.com，我们期待每一个领域的天才。
  </Accordion>
</Box>
    </Container>
    </Section>
  )
}

export default Faq