import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import moqiLogo from '../assets/moqiC_LOGO_B.svg'


const LogoText = styled.h1`
font-family: 'Akaya Telivigala', cursive;
font-size: ${props => props.theme.fontxxxl};
color: ${props => props.theme.text};
transition: all 0.2s ease;

&:hover{
    transform: scale(1.1);
}

@media (max-width: 64em){
font-size: ${props => props.theme.fontxxl};

}
`

const Logo = () => {
  return (
    <div>
      <img width={200} height={45} src={moqiLogo} alt="NFT" />
    </div>
  )
}

export default Logo